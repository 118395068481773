import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import React, { useState } from 'react'
const { countries } = require('assets/countries.json')
import { Dropdown } from 'semantic-ui-react'
import DatePicker from 'react-date-picker'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteAccount, logout, selectDeleteAccountStatus,
  selectUpdateMissingInfoError,
  selectUpdateMissingInfoStatus,
  updateMissingInfoRequest
} from 'store/account'
import { useCurrentUser, useModalActions } from 'common/hooks'
import { TextFieldFormsy } from 'common/components/text-field'
import Formsy from 'formsy-react'
import constants from 'app/constants'
import Button from 'common/ui/button'
import { i18n } from 'common/locale/i18n'
import '../deposit/ViewDeposit.scss'
import classNames from 'classnames'
import 'semantic-ui-css/semantic.min.css'
import { toastService } from 'api/toast'
import { useEffect } from 'react' 
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import './ViewPersonalInfo.scss'
import currencies from "common/util/currencies";
import {Sheet} from "react-modal-sheet";
import {CheckControl} from "common/components/form-controls";
import {removeSpaces} from "../../common/util/helpers";
import {feedUrl} from "../../urls";
import {useNavigate} from "react-router";

const RenderAdditionalInfos = props => {
  const { closeAfter = false, nullAllowed = false, currentUser, onSubmitModal, showDeleteAccountButton = false } = props || {}
  const dispatch = useDispatch()
  const modalActions = useModalActions()
  const isMobileSize = useIsMobileSize();
  const navigate = useNavigate()

  const [date, setDate] = useState()
  const [countryOfResidence, setCountryOfResidence] = useState(currentUser ? currentUser.residence_country : null)
  const [valid, setValid] = useState(false)
  const updateMissingInfoStatus = useSelector(selectUpdateMissingInfoStatus())
  const updateMissingInfoError = useSelector(selectUpdateMissingInfoError())?.error

  const [deleteAccountSelectedReasons, setDeleteAccountSelectedReasons] = useState([])
  const [deleteAccountOtherReason, setDeleteAccountOtherReason] = useState("")
  const [showOtherReasonInput, setShowOtherReasonInput] = useState(false)
  const [showDeleteAccountReasonsSelector, setShowDeleteAccountReasonsSelector] = useState(false)
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const deleteAccountStatus = useSelector(selectDeleteAccountStatus)

  const deleteAccountReasons = [
    {
      key:"lost_too_many_times",
      label:i18n('account_settings.delete_account.reasons.lost_too_many_times'),
    },
    {
      key:"markets_are_not_interesting",
      label:i18n('account_settings.delete_account.reasons.markets_are_not_interesting'),
    },
    {
      key:"ran_out_of_play_money",
      label:i18n('account_settings.delete_account.reasons.ran_out_of_play_money'),
    },
    {
      key:"lack_of_engagement_between_players",
      label:i18n('account_settings.delete_account.reasons.lack_of_engagement_between_players'),
    },
    {
      key:"other",
      label:i18n('account_settings.delete_account.reasons.other'),
    },
  ]

  useEffect(() => {
    setValid(false)
  }, [updateMissingInfoError])

  useEffect(() => {
    if (currentUser && currentUser.birth_date) {
      setDate(convertUTCToLocalDate(currentUser.birth_date))
    }
  }, [currentUser])

  const onFormValid = () => {
    if (!valid) {
      setValid(true)
    }
  }

  const onFormInvalid = () => {
    if (valid) {
      setValid(false)
    }
  }

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }

  const onFormSubmit = async data => {
    const dateToSave = new Date(date.toString().split(' ').slice(0, 4).join(' '))

    const year = dateToSave.getFullYear()
    const month = dateToSave.getMonth() + 1 // Months are zero-indexed
    const day = dateToSave.getDate()

    const missing_data = {
      birth_date: `${year}-${month}-${day}`,
      residence_country: countryOfResidence || currentUser?.residence_country,
      first_name: data.first_name != undefined ? data.first_name : currentUser?.first_name,
      last_name: data.last_name != undefined ? data.last_name : currentUser?.last_name,
      address: data.address != undefined ? data.address : currentUser?.address,
    }

    await dispatch(updateMissingInfoRequest(missing_data))
    toastService('success', { title: i18n('kyc_form.success_message') })
    // if we came from the deposit modal we don't close we just clear the pre-kyc-completed
    if (onSubmitModal) {
      onSubmitModal()
      return
    }
    if (closeAfter) modalActions.close()
  }
  const countryChanged = (_, data) => {
    setCountryOfResidence(data?.value)
  }

  const deleteAccountButtonClicked = (event) => {
    event.preventDefault()

    if(userHasFundsOnRealMoney())
    {
      toastService('fail', {
        title: i18n('account_settings.delete_account.errors.withdraw_before_delete.title'),
        body: i18n('account_settings.delete_account.errors.withdraw_before_delete.body'),
      })
    }
    else
    {
      setShowDeleteAccountReasonsSelector(true)
    }
  }

  const confirmAccountDeletion = () => {

    if(isOtherReasonCheckboxChecked() && !isOtherReasonTextValid()) return

    setIsLoading(true)

    let reasons_string = deleteAccountSelectedReasons.map((reason) => {

      if(reason.key === 'other')
      {
        return i18n('account_settings.delete_account.reasons.other')+': '+deleteAccountOtherReason
      }

      return reason.label
    }).join(', ')

    let payload = {
      reason:reasons_string,
    }

    dispatch(deleteAccount(payload))
      .then(() => {
        setIsLoading(false)

        dispatch(logout())
          .then(() => {

            navigate(feedUrl());

            toastService("success", {
              title: i18n('account_settings.delete_account.account_deleted_successfully'),
            });
          })
      });
  }

  const cancelAccountDeletion = () => {
    setDeleteAccountSelectedReasons([])
    setDeleteAccountOtherReason("")
    setShowOtherReasonInput(false)
    setShowDeleteAccountReasonsSelector(false)
    setShowDeleteAccountConfirmation(false)
  }

  /**
   * Returns if user has funds on any real money currency.
   */
  const userHasFundsOnRealMoney = () => {
    return Boolean(
      Object.keys(currentUser.wallet).filter(
        currency => currency !== currencies.OOM.id && currentUser.wallet[currency] > 0
      ).length
    )
  }

  const renderDeleteAccountReasonsSelector = () => {
    return (
      <div className="flex flex-col gap-6">
        <div className="text-base font-normal leading-[150%] text-Neutral-4">
          { i18n('account_settings.delete_account.reason_selector_info_message') }
        </div>

        <div className="flex flex-col gap-6">
          {deleteAccountReasons.map((reason) => {
            return (
              <div className="flex flex-row gap-2" key={reason.key}>
                <CheckControl active={isReasonSelected(reason.key)} onToggle={(event) => {deleteAccountReasonOnToggle(event, reason)}} />
                <div className="text-sm font-normal text-Neutral-6">{i18n('account_settings.delete_account.reasons.'+reason.key)}</div>
              </div>
            )
          })}

          {
            showOtherReasonInput && (
              <div>
                <Formsy>
                  <TextFieldFormsy
                    name="text"
                    onChange={otherReasonOnChange}
                    value={deleteAccountOtherReason}
                    required
                    placeholder={i18n('account_settings.delete_account.add_your_reason')}
                  />
                </Formsy>
              </div>
            )
          }

          <div className="flex flex-col-reverse gap-4 justify-content-end items-center md:flex-row">
            <button type="button" onClick={skipDeleteAccountReason} className="font-bold text-base text-[--color-brand-04] w-full md:w-fit">
              {i18n('account_settings.delete_account.skip_button_text')}
            </button>

            <Button
              onClick={submitDeleteAccountReason}
              size={isMobileSize ? 'full' : 'large'}
              disabled={deleteAccountSelectedReasons.length === 0 || (isOtherReasonCheckboxChecked() && !isOtherReasonTextValid()) }>
              {i18n('kyc_form.submit')}
            </Button>
          </div>
        </div>

      </div>
    )
  }

  const deleteAccountReasonOnToggle = (event, toggledReason) => {

    let selected_reasons = [...deleteAccountSelectedReasons]

    const selected_reason = selected_reasons.find((selected_reason) => selected_reason.key === toggledReason.key)

    if(selected_reason)
    {
      selected_reasons = selected_reasons.filter((reason) => reason.key != toggledReason.key)

      if(toggledReason.key === 'other')
      {
        setShowOtherReasonInput(false)
        setDeleteAccountOtherReason("")
      }
    }
    else
    {
      selected_reasons.push(toggledReason)

      if(toggledReason.key === 'other')
      {
          setShowOtherReasonInput(true)
      }
    }

    setDeleteAccountSelectedReasons(selected_reasons)
  }

  const otherReasonOnChange = (value) => {
    setDeleteAccountOtherReason(value)
  }

  const isReasonSelected = (reasonKey) => {
    return !! deleteAccountSelectedReasons.find((selected_reason) => selected_reason.key === reasonKey)
  }

  const submitDeleteAccountReason = () => {
    setShowDeleteAccountReasonsSelector(false)
    setShowDeleteAccountConfirmation(true)
  }

  const skipDeleteAccountReason = () => {
    setDeleteAccountSelectedReasons([])
    setDeleteAccountOtherReason("")
    setShowOtherReasonInput(false)
    setShowDeleteAccountReasonsSelector(false)
    setShowDeleteAccountConfirmation(true)
  }

  const isOtherReasonCheckboxChecked = () => {
    return !! deleteAccountSelectedReasons.find((reason) => reason.key === 'other')
  }

  const isOtherReasonTextValid = () => {
    return removeSpaces(deleteAccountOtherReason)
  }

  const renderDeleteAccountConfirmation = () => {
    return (
      <div className="flex flex-col gap-6">
        <div className="text-base font-normal leading-[150%] text-Neutral-4">
          { i18n('account_settings.delete_account.confirmation_info_message') }
        </div>

        <div className="flex flex-col-reverse gap-6 md:gap-4 justify-content-end items-center md:flex-row">
          <button type="button" onClick={cancelAccountDeletion} className="font-bold text-base text-[--color-brand-04] w-full md:w-fit">
            {i18n('account_settings.delete_account.cancel_button_text')}
          </button>

          <Button
            onClick={confirmAccountDeletion}
            variant="negative"
            size={isMobileSize ? 'full' : 'large'}
            disabled={deleteAccountStatus === constants.LOADING || isLoading} /*TODO: temporary until i ask amin about handling loading from store*/
            loading={deleteAccountStatus === constants.LOADING || isLoading}>
            {i18n('account_settings.delete_account.confirm_deletion_button_text')}
          </Button>
        </div>
      </div>
    )
  }

  if (!currentUser) return null
  return (
    <div>
      <div className="view-crypto-cash-in__missingData__title">{i18n('cash_in.missing_data.title')}</div>
      <div className="text-sm text-Neutral-4 my-4">{i18n('cash_in.missing_data.description')}</div>
      <Formsy onValid={onFormValid} onInvalid={onFormInvalid} onValidSubmit={onFormSubmit}>
        <div className="view-crypto-cash-in__missingData--grid">
          <TextFieldFormsy
            name="first_name"
            type="text"
            value={currentUser.first_name}
            placeholder={i18n('kyc_form.first_name_placeholder')}
            label={i18n('kyc_form.first_name')}
            validations={!nullAllowed && 'minLength:2'}
            validationError={i18n('kyc_form.error_first_name')}
            required={!nullAllowed}
            error={
              updateMissingInfoError && updateMissingInfoError['first_name'] && updateMissingInfoError['first_name'][0]
            }
          />
          <TextFieldFormsy
            name="last_name"
            type="text"
            value={currentUser.last_name}
            placeholder={i18n('kyc_form.last_name_placeholder')}
            label={i18n('kyc_form.last_name')}
            validations={!nullAllowed && 'minLength:2'}
            validationError={i18n('kyc_form.error_last_name')}
            required={!nullAllowed}
            error={
              updateMissingInfoError && updateMissingInfoError['last_name'] && updateMissingInfoError['last_name'][0]
            }
          />

          <div className="countryOfResidence">
            <label className="text-field-el__label" htmlFor="countryOfResidence">
              {i18n('kyc_form.country')}
            </label>
            <div>
              <Dropdown
                onChange={countryChanged}
                name="countryOfResidence"
                id="countryOfResidenceDropdown"
                labeled="true"
                options={countries}
                value={countryOfResidence}
                placeholder={i18n('kyc_form.country_placeholder')}
                fluid
                search
                selection
                error={
                  updateMissingInfoError &&
                  updateMissingInfoError['residence_country'] &&
                  updateMissingInfoError['residence_country'][0]
                }
              />
            </div>
          </div>
          <div className={classNames('datePicker', { datePicker__open: date })}>
            <label htmlFor="date" className="text-field-el__label">
              {i18n('kyc_form.date')}
            </label>
            <DatePicker
              calendarClassName={'hide_calendar'}
              name="date"
              maxDate={new Date()}
              onChange={setDate}
              value={date && new Date(date)}
              calendarIcon={null}
              clearIcon={null}
              yearPlaceholder={'YYYY'}
              monthPlaceholder={'MM'}
              dayPlaceholder={'DD'}
              error={
                updateMissingInfoError &&
                updateMissingInfoError['birth_date'] &&
                updateMissingInfoError['birth_date'][0]
              }
            />
          </div>
          <div className="!mt-4 md:!mt-0 md:col-span-2">
            <TextFieldFormsy
              name="address"
              type="text"
              value={currentUser.address}
              placeholder={i18n('kyc_form.address_placeholder')}
              label={i18n('kyc_form.address')}
              validations="minLength:2"
              validationError={i18n('kyc_form.error_address')}
              required={!nullAllowed}
              error={
                updateMissingInfoError && updateMissingInfoError['address'] && updateMissingInfoError['address'][0]
              }
            />
          </div>
        </div>

        <div className={`w-full mt-4 flex flex-col-reverse md:!flex-row ${showDeleteAccountButton ? 'justify-content-between' : 'justify-content-end'}`}>
          {
            showDeleteAccountButton && (
              <button type="button" onClick={deleteAccountButtonClicked} className="flex justify-content-center items-center mt-4 text-sm text-Neutral-4 underline w-full p-4 md:w-fit md:!p-0 md:!mt-0">
                { i18n("account_settings.delete_account.delete_account_button_text") }
              </button>
            )
          }

          <Button
            loading={updateMissingInfoStatus === constants.LOADING}
            size={isMobileSize ? 'full' : 'large'}
            disabled={!nullAllowed && (!valid || !date || !countryOfResidence)} className="w-full md:w-fit">
            {i18n('kyc_form.submit')}
          </Button>
        </div>
      </Formsy>

      {/* Delete account reasons selector */}

      {
        !isMobileSize &&  <Modal position="center" isOpen={showDeleteAccountReasonsSelector} onOverlayClick={() => cancelAccountDeletion()}>
          <ActionSheetLayout
            title={i18n('account_settings.delete_account.reason_selector_title')}
            content={renderDeleteAccountReasonsSelector()}
            action={null}
            buttonDisabled={true}
            onButtonClick={null}
            footer={null}
            showCloseButton={true}
            onClose={() => cancelAccountDeletion()}
            headerClassList=" !text-xl font-semibold leading-[133%] !py-5"
            className="max-w-[508px]"
          />
        </Modal>
      }

      {
        isMobileSize && <Sheet isOpen={showDeleteAccountReasonsSelector} onClose={() => cancelAccountDeletion()} detent="content-height" disableScrollLocking>
          <Sheet.Container>
            <Sheet.Content className="bg-Neutral-1">
              <ActionSheetLayout
                title={i18n('account_settings.delete_account.reason_selector_title')}
                content={renderDeleteAccountReasonsSelector()}
                action={null}
                buttonDisabled={true}
                onButtonClick={null}
                footer={null}
                showCloseButton={true}
                onClose={() => cancelAccountDeletion()}
                headerClassList="px-6 !py-4 text-xl"
                containerClass="pl-8 pr-8 pt-4 ml-0 mr-0"
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      }

      {/* Delete account confirmation */}

      {
        !isMobileSize &&  <Modal position="center" isOpen={showDeleteAccountConfirmation} onOverlayClick={() => cancelAccountDeletion()}>
          <ActionSheetLayout
            title={i18n('account_settings.delete_account.confirmation_title')}
            content={renderDeleteAccountConfirmation()}
            action={null}
            buttonDisabled={true}
            onButtonClick={null}
            footer={null}
            showCloseButton={true}
            onClose={() => cancelAccountDeletion()}
            headerClassList=" !text-xl font-semibold leading-[133%] !py-5"
            containerClass="pt-0"
            className="max-w-[560px]"
          />
        </Modal>
      }

      {
        isMobileSize && <Sheet isOpen={showDeleteAccountConfirmation} onClose={() => cancelAccountDeletion()} detent="content-height" disableScrollLocking>
          <Sheet.Container>
            <Sheet.Content className="bg-Neutral-1">
              <ActionSheetLayout
                title={i18n('account_settings.delete_account.confirmation_title')}
                content={renderDeleteAccountConfirmation()}
                action={null}
                buttonDisabled={true}
                onButtonClick={null}
                footer={null}
                showCloseButton={true}
                onClose={() => cancelAccountDeletion()}
                headerClassList="px-6 !py-4 text-xl"
                containerClass="pl-8 pr-8 pt-4 ml-0 mr-0"
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      }
    </div>
  )
}

const ViewPersonalInfo = ({ header, onSubmitModal }) => {
  const modalActions = useModalActions()
  const currentUser = useCurrentUser()
  const onClose = () => {
    modalActions.close()
  }
  const renderTitle = () => {
    return (
      <div className="view-crypto-cash-in__header">
        <div>{i18n('settings.update_personal_info')}</div>
      </div>
    )
  }
  return (
    <Modal isOpen={true} position="bottom" onCloseRequested={onClose}>
      <ActionSheetLayout
        onClose={() => onClose()}
        className="view-crypto-withdraw view-crypto-cash-in--missing-info"
        showCloseButton={true}
        title={header || renderTitle()}
        content={RenderAdditionalInfos({ closeAfter: true, nullAllowed: true, currentUser, onSubmitModal })}
      />
    </Modal>
  )
}
export { RenderAdditionalInfos, ViewPersonalInfo }

import React from 'react'
import './SubCategoryList.scss'
import { Link } from 'react-router-dom'
import { categoryListUrl, keepQueryString } from 'urls'
import getCatIcon from 'api/catergoryIcon'

function SubCategoryList({ subcategories }) {
  if (!subcategories) {
    return null
  }

  const renderSubcategoryList = () => {
    const subcategoriesLinks = subcategories
      .filter(c => c != null)
      .map((category, index) => {
        return (
          <Link
            key={`category-link-${category.id}`}
            className={'subcategory-list__tab' + (index === 0 ? ' subcategory-list__tab--first' : '')}
            to={category.href ? category.href : keepQueryString(categoryListUrl(category))}>
            <i>{getCatIcon(category.slug)}</i>
            <span>{category.title}</span>
          </Link>
        )
      })

    return subcategoriesLinks
  }

  if (subcategories.length) {
    return renderSubcategoryList()
  }

  return null
}

export default SubCategoryList

import {createSlice} from '@reduxjs/toolkit'
import constants from 'app/constants'
import requestService from 'common/services/request'
import {toastService} from 'api/toast'

const initialState = {
  feedback: {
    status: constants.IDLE,
    error: null,
  },
}

const feedback = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    postFeedbackStart: state => {
      state.feedback.status = constants.LOADING
    },
    postFeedbackSuccess: (state) => {
      state.feedback.status = constants.COMPLETE
    },
    postFeedbackError: (state) => {
      state.feedback.status = constants.ERROR
    },
  },
})

export const selectFeedbacksStatus = state => state.feedback.feedback.status

export const {
  postFeedbackStart,
  postFeedbackSuccess,
  postFeedbackError,
} = feedback.actions

export default feedback.reducer

export const postFeedback = (payload) => async dispatch => {
  await dispatch(postFeedbackStart())

  try {
    const response = await requestService.post(`/suggestions/`, payload)
    const data = await response.json()

    await dispatch(postFeedbackSuccess(data))
  }
  catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(postFeedbackError(err))
  }
}

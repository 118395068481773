import React from 'react'

const sendIcon = ({ className = '', width = 21, height = 18, color = '#1591ED' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18" width={width} height={height}>
      <path d="M0 18l21-9L0 0v7l15 2-15 2z" fill={color} fillRule="nonzero" />
    </svg>
  )
}

export default sendIcon

import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import './TextField.scss'
import { withFormsy } from 'formsy-react'
import { DangerIcon, EyeCrossed, EyeIcon } from '../icons'
import IconContainer from 'common/ui/iconContainer'
const TextField = React.forwardRef(
  (
    {
      label,
      error = '',
      value = '',
      helperText,
      onChange = () => {},
      onBlur = () => {},
      className = '',
      suffix = '',
      prefix = '',
      disabled = false,
      format,
      containerClassName = '',
      maxChars,
      autofocus = false,
      icon,
      ...inputProps
    },
    ref
  ) => {
    const textField = useRef()
    const [suffixPosition, setSuffixPosition] = useState(0)
    const [isFocused, setIsFocused] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const textWidthRef = useRef(null)
    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = e => {
      setIsFocused(false)
      onBlur(e.target.value)
    }
    useEffect(() => {
      if (autofocus && textField.current) {
        textField?.current.focus()
      }
    }, [autofocus, textField])

    useEffect(() => {
      if (suffix) {
        updateSuffixPosition()
      }
    }, [value])

    const updateSuffixPosition = () => {
      if (textWidthRef.current) {
        setSuffixPosition(textWidthRef.current.offsetWidth + 5)
      }
    }

    const containerClasses = classnames('text-field-el__container', containerClassName, {
      'text-field-el__container--error': error,
      'text-field-el__container--disabled': disabled,
      'text-field-el__container--noLabel': !label,
      'text-field-el__container--withvalue': value,
      'text-field-el__container--active': (isFocused || value) && label,
      'text-field-el__container--text-area': inputProps.isTextArea,
      'text-field-el__container--read-only': inputProps.readOnly,
    })

    const textFieldClassNames = classnames(className, 'text-field-el__input')
    const randomId = Math.random()
    const generalProps = {
      ...inputProps,
      id: inputProps.id || randomId,
      ref: ref || textField,
      onChange: e => {
        const value = e.target.value
        if (!maxChars || (maxChars && value.length <= maxChars && onChange))
          return onChange(format ? format(value) : value)
      },
      onBlur: handleBlur,
      value: format ? format(value) : value,
      className: textFieldClassNames,
      onFocus: handleFocus,
      type: inputProps.type === 'password' ? (passwordVisible ? 'text' : 'password') : inputProps.type,
      placeholder: inputProps.placeholder,
    }
    return (
      <div className="w-full space-y-2">
        {label && (
          <label className="text-field-el__label" htmlFor={inputProps.id || randomId}>
            {label}
          </label>
        )}
        <div className={containerClasses}>
          <div className="text-field-el__input-container">
            <div className="text-field-el__content">
              {prefix && <span className="text-field-el__prefix">{prefix}</span>}
              <div className="text-field-el__input-wrap">
                {!inputProps.isTextArea ? <input {...generalProps} /> : <textarea {...generalProps} />}
              </div>
              <span ref={textWidthRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'pre' }}>
                {value || inputProps.placeholder}
              </span>
              {suffix && (
                <span style={{ left: suffixPosition }} className="text-field-el__suffix">
                  {suffix}
                </span>
              )}
            </div>
            <div className="text-field-el__icons-container">
              {inputProps.type == 'password' && (
                <div onClick={() => setPasswordVisible(v => !v)} style={{ display: 'flex' }}>
                  {passwordVisible ? (
                    <IconContainer size={19}>
                      <EyeIcon width="19px" outline />
                    </IconContainer>
                  ) : (
                    <IconContainer size={19}>
                      <EyeCrossed width="18px" />
                    </IconContainer>
                  )}
                </div>
              )}
              {error && (
                <IconContainer size={19}>
                  <DangerIcon width="19px" outline />
                </IconContainer>
              )}
              {icon && <IconContainer size={19}>{icon}</IconContainer>}
            </div>
          </div>
        </div>

        {error && <div className="text-field-el__error">{error}</div>}
        {helperText && <div className="text-field-el__helper">{helperText}</div>}
      </div>
    )
  }
)

const TextFieldFormsy = withFormsy(props => { 
  const [touched, setTouched] = useState(false)
  const formsyBindProps = {
    ...props,
    onChange: value => {
      props.setValue(value)
      if (props.onChange) {
        props.onChange(value)
        if (!props.isValidValue(value) && props.onError) props.onError()
      }
    },
    onBlur: e => {
      setTouched(!!props.value)
      if (props.validateOnBlur) props.validateOnBlur(e)
    },
    value: props.value || '',
    error: props.error || (props.value && touched && props.errorMessage),
  }
  return <TextField ref={props.innerRef} {...formsyBindProps} />
})

export default TextField
export { TextFieldFormsy }

import { EntertainmentIcon, FinanceIcon, PoliticsIcon, ScienceIcon, SportsIcon } from 'common/components/icons'
import CalendarIcon from 'common/components/icons/calendar'
import React from 'react'

const getCatIcon = (catLabel, color = 'currentColor', width = '20px') => {
  switch (catLabel) {
    case 'politics':
      return <PoliticsIcon width={width} fillcolor={color} />
    case 'sports':
      return <SportsIcon width={width} fillcolor={color} />
    case 'business-finance':
      return <FinanceIcon width={width} fillcolor={color} />
    case 'science':
      return <ScienceIcon width={width} fillcolor={color} />
    case 'entertainment':
      return <EntertainmentIcon width={width} fillcolor={color} />
    case 'closes-in-2024':
      return <CalendarIcon width={width} fillcolor={color} />

    default:
      break
  }
}
export default getCatIcon

import React from 'react'

export default function CalendarIcon({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.color || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3H18V1H16V7H18V5H21V20H3V5H5V3H3C1.896 3 1 3.896 1 5V20C1 21.104 1.896 22 3 22H21C22.104 22 23 21.104 23 20V5C23 3.896 22.104 3 21 3Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 5H15V3H8V1H6V7H8V5Z" />
      <rect x="5" y="9" width="4" height="4" />
      <rect x="10" y="9" width="4" height="4" />
      <rect x="15" y="9" width="4" height="4" />
      <rect x="5" y="14" width="4" height="4" />
      <rect x="10" y="14" width="4" height="4" />
    </svg>
  )
}

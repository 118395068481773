import React, { useState, useRef, useEffect } from "react";

const OverlayPanel = ({
                        isVisible = false, //for showing the overlay by default
                        isOverlayEnabled = true, //for displaying or never displaying overlay
                        children,
                        content,
                        position = 'top-right-up-left',
                        customPosition,
                        className,
                        style,
                        showDelay = 0,
                        hideDelay = 300,
                        showMode = "hover",     // New: controls how the overlay is triggered (options: "hover" or "click")
                        hideOnLeave = true,      // New: controls whether the overlay hides when the mouse leaves
                        onHide = () => {},
                      }) => {
  const overlayRef = useRef(null);
  const timeoutRef = useRef(null);
  const [visibilityHandler, setVisibilityHandler] = useState(false);

  // Handle show/hide based on isVisible and show/hide delays
  useEffect(() => {
    if (isVisible) {
      // Clear existing timeout when showing
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Show the overlay with the specified show delay
      timeoutRef.current = setTimeout(() => {
        setVisibilityHandler(true);
      }, showDelay);
    } else if (hideOnLeave) {
      // Hide the overlay with the specified hide delay
      timeoutRef.current = setTimeout(() => {
        setVisibilityHandler(false);
      }, hideDelay);
    }

    // Cleanup on component unmount or when isVisible changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isVisible, showDelay, hideDelay, hideOnLeave]);

  // Handle overlay mouse enter (for hover mode)
  const handleOverlayMouseEnter = () => {
    if (hideOnLeave && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // Handle overlay mouse leave (for hover mode)
  const handleOverlayMouseLeave = () => {
    if (hideOnLeave) {
      timeoutRef.current = setTimeout(() => {
        setVisibilityHandler(false);
        onHide()
      }, hideDelay);
    }
  };

  // Handle toggle for click mode
  const handleClick = () => {
    if (showMode === "click") {
      setVisibilityHandler(!visibilityHandler);
      if(!visibilityHandler){
        onHide()
      }
    }
  };

  const positions = {

    // Top left
    'top-left-up-left': { right: '100%', bottom: '100%' },
    'top-left-up-right': { left: '0', bottom: '130%' },
    'top-left-down-left': { right: '120%', top: '0' },
    'top-left-down-right': { left: '0', top: '0' },

    // Top right
    'top-right-up-left': { right: '0', bottom: '130%' },
    'top-right-up-right': { left: '100%', bottom: '100%' },
    'top-right-down-left': { right: '0', top: '0' },
    'top-right-down-right': { left: '130%', top: '0' },

    // top middle

    'top-middle-up-middle': {
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '120%',
    },

    'top-middle-down-middle': {
      left: '50%',
      transform: 'translateX(-50%)',
      top: '0',
    },

    // Bottom left
    'bottom-left-up-left': { right: '120%', bottom: '0' },
    'bottom-left-up-right': { left: '0', bottom: '0' },
    'bottom-left-down-left': { right: '120%', top: '120%' },
    'bottom-left-down-right': { left: '0', top: '120%' },

    // Bottom right
    'bottom-right-up-left': { right: '0', bottom: '0' },
    'bottom-right-up-right': { left: '120%', bottom: '0' },
    'bottom-right-down-left': { right: '0', top: '120%' },
    'bottom-right-down-right': { left: '100%', top: '100%' },

    // bottom middle

    'bottom-middle-up-middle': {
      left: '50%',
      transform: 'translateX(-50%)',
      top: '120%',
    },

    'bottom-middle-down-middle': {
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '0',
    },
  };

  const overlayPosition = customPosition ? customPosition : positions[position]

  return (
    <div
      className="relative inline-block"
      ref={overlayRef}
      onMouseEnter={showMode === "hover" ? () => setVisibilityHandler(true) : null}
      onMouseLeave={showMode === "hover" ? handleOverlayMouseLeave : null}
      onClick={showMode === "click" ? handleClick : null}  // Handle click mode
    >
      {/* The element on which we show the overlay */}
      {children}

      {/* The overlay */}
      {(visibilityHandler && isOverlayEnabled) && (
        <div
          className={`absolute bg-Neutral-1 shadow-[0px_8px_24px_0px_rgba(0,0,0,0.16)] rounded-2xl p-4 z-[1000] w-max ${className}`}
          style={{ ...overlayPosition, ...style }}
          onMouseEnter={handleOverlayMouseEnter}
          onMouseLeave={handleOverlayMouseLeave}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default OverlayPanel;

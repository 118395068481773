import React, { useState, useEffect } from 'react'
import { CrossIcon, MagnifyIcon } from 'common/components/icons'
import './SearchBox.scss'

const SearchBox = ({
  autofocus = false,
  onChange = null,
  onFocus = null,
  initialValue = '',
  placeholder,
  onEnter,
  id,
  _ref,
}) => {
  const [value, setValue] = useState(initialValue)
  const [focus, setFocus] = useState(autofocus)
  const [debouncedUserData, setDebouncedUserData] = useState()

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedUserData(value)
    }, 400) // Adjust debounce time as needed


    return () => {
      clearTimeout(handler)
    }
  }, [value])

  useEffect(() => {
    onChange(value)
  }, [debouncedUserData])

  useEffect(() => {
    if (_ref && _ref.current && focus) {
      _ref.current.focus()
      onFocus && onFocus()
    } else if (_ref && _ref.current && !focus) {
      _ref.current.blur()
    }
  }, [focus, onFocus])

  const handleClear = () => {
    setValue('')
    setFocus(true)
    onChange('')
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
  }
  const handleKeyUp = event => {
    if (event.keyCode === 13 && onEnter) {
      event.target.blur()
      onEnter(value)
    }
  }

  return (
    <div className="search-box relative">
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        value={value}
        id={id}
        ref={_ref}
        className="bg-Neutral-1"
      />
      {
        value && (
          <div className="w-[55px] mr-16 md:!mr-[55px] absolute right-0 flex justify-content-center items-center">
            <CrossIcon onClick={handleClear} width="10px" style={{ cursor: 'pointer' }}/>
          </div>
        )
      }
        <MagnifyIcon onClick={onEnter} width="14px"  className="cursor-pointer"/>
    </div>
  )
}

export default SearchBox

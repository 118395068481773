import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTagsByName,
  resetAllMarkets,
  selectMarketsByTagName,
  selectMarketsErrorByTagName,
  selectMarketsPaginationByTagName,
  selectMarketsStatusByTagName,
} from 'store/markets'
import MarketListTemplate from 'questions/components/market-list-template'
import { selectActiveMarketType } from 'store/app'
import { fromEntries } from 'common/util/fromEntries'
import { urlSearchParams } from 'common/util/urlSearchParams'
import './ViewTags.scss'
import constants from 'app/constants'
import Helmet from 'common/components/helmet'
import { i18n } from 'common/locale/i18n'
import Breadcrumb from 'common/components/breadcrumb'
import { keepQueryString, tagsListUrl } from 'urls'
import { getTagName, selectTempTagBySlug } from 'store/tags'
import { useState } from 'react'
import QuickBet from 'questions/components/quick-bet'
const ViewTags = () => {
  const { tag } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const [outcomeSelected, setOutcomeSelected] = useState(null)
  const [marketSelected, setMarketSelected] = useState(null)
  const queryParams = fromEntries(new urlSearchParams(location.search))
  const activeMarketType = useSelector(selectActiveMarketType)
  const markets = useSelector(selectMarketsByTagName(tag))
  const tagData = useSelector(selectTempTagBySlug(tag))
  const status = useSelector(selectMarketsStatusByTagName(tag))
  const error = useSelector(selectMarketsErrorByTagName(tag))
  const pagination = useSelector(selectMarketsPaginationByTagName(tag))

  useEffect(() => {
    if ((status === constants.COMPLETE || status === constants.IDLE) && activeMarketType) {
      dispatch(resetAllMarkets())
      dispatch(fetchTagsByName(tag, activeMarketType, queryParams))
    }
  }, [activeMarketType, location.search])

  useEffect(() => {
    dispatch(getTagName(tag))
  }, [tag])
  const renderParentTag = () => {
    if (!tag) {
      return null
    }

    const breadcrumbs = [{ value: i18n('search.tabs.tags'), href: keepQueryString(tagsListUrl()) }]
    return (
      <>
        <Breadcrumb items={breadcrumbs} showLastArrow />
        <h1 className="view-tags__header__title">{tagData ? tagData.name : tag}</h1>
      </>
    )
  }
  const renderMakeBet = _outcomeSelected => {
    const outcome = _outcomeSelected
    return (
      <QuickBet
        marketSelected={marketSelected}
        outcome={outcome}
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        shouldShow={marketSelected && marketSelected.scoring_rule_metadata}
        place="Tags page"
      />
    )
  }
  return (
    <div className="view-tags">
      <div className="view-tags__header !px-4 md:!px-[30px]">{renderParentTag()}</div>
      <MarketListTemplate
        activeMarketType={activeMarketType}
        queryParams={queryParams}
        markets={markets}
        pagination={pagination}
        status={status}
        error={error}
        onLoadMore={(activeMarketType, options) => dispatch(fetchTagsByName(tag, activeMarketType, options))}
        returnUrl={location.pathname}
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        marketSelected={marketSelected}
        OutcomeSelected={outcomeSelected}
        page="tags"
      />
      <Helmet
        title={i18n('tag.meta_tags.title', { tag: tagData ? tagData.name : tag })}
        description={i18n('tag.meta_tags.description', { tag, marketCount: (pagination && pagination.total) || 50 })}
      />
      {renderMakeBet(outcomeSelected)}
    </div>
  )
}

export default ViewTags

import React, { useEffect, useState } from 'react'
import { i18n } from 'common/locale/i18n'
import MarketListHeader from 'explore/components/active-markets'
import FilterDropdownPanel from 'common/components/filter-dropdown-panel'
import InfiniteGrid from 'common/components/InfiniteGrid'
import constants from 'app/constants'
import Modal from 'common/components/modal/Modal'

// import Market from 'questions/components/market'

import './MarketListTemplate.scss'
import MarketCard from '../market-card/MarketCard'
import classNames from 'classnames'
import { ArrowUpDown } from 'common/components/icons'
import { RadioControl } from 'common/components/form-controls'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { useNavigate } from 'react-router-dom'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const MarketListTemplate = ({
  activeMarketType,
  queryParams,
  markets,
  pagination,
  status,
  error,
  onLoadMore,
  setMarketSelected = () => {},
  setOutcomeSelected = () => {},
  marketSelected,
  OutcomeSelected,
  page = 'feedd',
}) => {
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true)
  const [sort, setSort] = useState()
  const [sortModalOpen, setSortModalOpen] = useState(false)
  const navigate = useNavigate()
  const isMobileSize = useIsMobileSize()

  const isLoading = status === constants.LOADING || status === constants.IDLE

  useEffect(() => {
    if (status === constants.COMPLETE) {
      setIsLoadingFirstTime(false)
    }
  }, [status])

  useEffect(() => { 
    if (!isLoading && markets.length > 0) {
      setTimeout(() => { 
        window.prerenderReady = true
      }, 1000)
    }
  }, [isLoading, markets])

  const onRender = ({ index }) => {
    const market = markets[index]
    if (!market) return

    const classList = classNames('question-card', 'question-card--show-bet-status', {
      'question-card--is-selected': market && marketSelected && marketSelected.id === market.id,
    })

    return (
      <article className={classList}>
        <MarketCard
          key={'question-card-' + market.id}
          question={market}
          categories={market && market.category}
          inlineBehavior
          showBetStatus
          showSelectedOutcome
          isSelectedToBet={market && marketSelected && marketSelected.id === market.id}
          currentOutcomeSelection={OutcomeSelected}
          onOutcomeSelected={outcome => {
            setOutcomeSelected(outcome)
            setMarketSelected(market)
          }}
          setMarketSelected={setMarketSelected}
        />
      </article>
    )
  }
  const onRenderSegments = ({ index }) => {
    const heights = [
      [4, 2],
      [2, 5],
      [3, 1],
      [2, 5],
    ]
    return (
      <>
        {heights[index].map(height => (
          <div className="market-page__card-skeleton " key={'skeleton' + height}>
            <div className="market-page__card-skeleton--header skeleton"></div>

            <div className="market-page__card-skeleton--outcomes ">
              {[...Array(height).keys()].map(_ => (
                <div key={_} className="market-page__card-skeleton--outcome skeleton"></div>
              ))}
            </div>
            <div className="market-page__card-skeleton--footer ">
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
            </div>
          </div>
        ))}
      </>
    )
  }
  const renderSortDropdown = () => {
    const hideModal = () => {
      setSortModalOpen(false)
    }
    const renderList = () => {
      const sortByOptions = [
        { value: '-created_on', text: i18n('markets.most_recent') },
        { value: 'bet_end_date', text: i18n('markets.bet_end_date') },
        { value: '-wagers_count', text: i18n('markets.number_of_bets') },
        { value: '-volume', text: i18n('markets.volume') },
        { value: '-relevance', text: i18n('markets.relevance') },
      ]
      return (
        <ul>
          {sortByOptions.map((header, index) => (
            <li
              key={'sort-portfolio-dropdown' + index}
              className="flex gap-2 px-2 py-3"
              onClick={() => {
                // handleSort(header)
                const _queryParams = Object.fromEntries(new urlSearchParams(location.search))
                _queryParams.ordering = header.value
                setSort(header.value)
                navigate(location.pathname + '?' + new URLSearchParams(_queryParams).toString())
                hideModal()
              }}>
              <RadioControl active={sort === header.value} />
              <div className="flex items-center gap-2 cursor-pointer">{header.text}</div>
            </li>
          ))}
        </ul>
      )
    }
    return (
      <>
        <div
          onClick={() => setSortModalOpen(true)}
          className="rounded-full flex w-10 h-10 items-center justify-center bg-Neutral-1 border border-solid border-Neutral-3">
          <ArrowUpDown width="13px" fill="currentColor" />
        </div>

        <Modal isOpen={sortModalOpen} position="bottom" onCloseRequested={hideModal}>
          <ActionSheetLayout
            title={i18n('markets.sort_by')}
            content={renderList()}
            onClose={hideModal}
            showCloseButton
          />
        </Modal>
      </>
    )
  }
  return (
    <React.Fragment>
      <div>
        <MarketListHeader
          isLoading={isLoading}
          numberOfActiveBets={!isLoading && pagination.total}
          isRealMoney={activeMarketType === constants.REAL_MONEY}
          filters={
            !isLoadingFirstTime && (
              <div className="flex justify-between gap-2 w-full items-center">
                <FilterDropdownPanel
                  className="market-page__filters  md:flex-wrap max-h-[calc(100vh-100px)] md:max-h-none pb-32 md:pb-0"
                  queryParams={queryParams}
                  page={page}
                  containerClass="!p-0  "
                />
                {isMobileSize && renderSortDropdown()}
              </div>
            )
          }
        />
      </div>

      <InfiniteGrid
        className="market-page__wrapper !px-4 md:!px-[30px]"
        columnClassName="market-page__column"
        error={error}
        isLoading={isLoading}
        loadedRows={isLoading && markets.length === 0 ? 4 : markets.length}
        onLoadMore={options => onLoadMore(activeMarketType, options)}
        onRowRender={isLoading && markets.length === 0 ? onRenderSegments : onRender}
        placeholder={
          Object.keys(queryParams).length > 0 ? i18n('markets.no_markets_clear_filters') : i18n('markets.no_markets')
        }
        totalRows={pagination ? pagination.total : 0}
        useMasonry={true}
        autoLoading={false}
        breakpoints={{ default: 4, 1920: 4, 1440: 3, 1024: 2, 981: 1 }}
      />
    </React.Fragment>
  )
}

export default MarketListTemplate

import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { i18n } from 'common/locale/i18n'
import constants from 'app/constants'
import shareService from 'social/services/share'
import Helmet from 'common/components/helmet'
import OnboardingTour from 'onboarding/components/OnboardingTour'
import { feedUrl } from 'urls'
import { useCurrentUser } from 'common/hooks'
import {
  fetchFeed,
  selectFeedMarkets,
  selectFeedMarketsError,
  selectFeedMarketsPagination,
  selectFeedMarketsStatus,
  selectMarketRepository,
  selectShouldLoadFeed,
} from 'store/markets'
import {
  fetchFeedBanners,
  selectFeedBanners,
  selectFeedBannersStatus,
  selectShouldLoadFeedBanners,
} from 'store/banners'
import { selectAbTestsStatus, selectActiveMarketType, selectPageLayout } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { addCampain, selectUserFromAllowedCountry } from 'store/account'
import { useLocation } from 'react-router-dom'
import { fromEntries } from 'common/util/fromEntries'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { selectRootCategoryList } from 'store/categories'
import CategoryNavigation from 'explore/components/category-navigation'

import './ViewFeed.scss'
import MarketListTemplate from 'questions/components/market-list-template'
import ErrorHandler from 'app/components/error-handler'
import futuurCover from 'landing/assets/futuur.com.png'
import QuickBet from 'questions/components/quick-bet'
import FAQs from 'views/FAQs'
import HotTopic from 'questions/components/hot-topic'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const ViewFeed = () => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState(fromEntries(new urlSearchParams(location.search)))
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const activeMarketType = useSelector(selectActiveMarketType)
  const markets = useSelector(selectMarketRepository)
  const abTestsStatus = useSelector(selectAbTestsStatus)
  const pageLayout = useSelector(selectPageLayout)
  const feedIds = useSelector(selectFeedMarkets)
  const feedPagination = useSelector(selectFeedMarketsPagination)
  const shouldLoadFeed = useSelector(selectShouldLoadFeed(activeMarketType, location.search))
  const error = useSelector(selectFeedMarketsError)
  const feedStatus = useSelector(selectFeedMarketsStatus)
  const banners = useSelector(selectFeedBanners)
  const bannersLoading = useSelector(selectFeedBannersStatus)
  const shouldLoadBanners = useSelector(selectShouldLoadFeedBanners(activeMarketType))
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const categories = useSelector(selectRootCategoryList(activeMarketType))
  const [outcomeSelected, setOutcomeSelected] = useState(null)
  const [marketSelected, setMarketSelected] = useState(null)
  const isMobileSize = useIsMobileSize()

  useEffect(() => {
    if (shouldLoadBanners && !pageLayout.appLoading) {
      dispatch(fetchFeedBanners(activeMarketType))
    }
  }, [pageLayout.appLoading, activeMarketType])

  useEffect(() => {
    setQueryParams(fromEntries(new urlSearchParams(location.search)))
  }, [location.search])

  useEffect(() => {
    if (pageLayout.appLoading || abTestsStatus !== constants.COMPLETE) return

    if (shouldLoadFeed) {
      dispatch(fetchFeed(activeMarketType, { limit: 30, ...queryParams }, location.search, shouldLoadFeed))
    }
  }, [pageLayout.appLoading, activeMarketType, queryParams, abTestsStatus])

  useEffect(() => {
    if (location.pathname.includes('/manifest')) addCampain('manifest')
  }, [])
  const renderHelmet = () => {
    const props = {
      title: i18n('landing.meta_tags.title'),
      description: i18n('landing.meta_tags.description', { marketCount: feedPagination.total }),
      images: futuurCover,
      url: shareService.shareableUrl(feedUrl()),
    }
    return <Helmet id="markets-helmet" {...props} />
  }
  /* we may use this for showing a custom category */
  // const renderCustomCategory = () => {
  //   return {}
  // }
  const renderMakeBet = _outcomeSelected => {
    const outcome = _outcomeSelected
    return (
      <QuickBet
        marketSelected={marketSelected}
        outcome={outcome}
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        shouldShow={marketSelected && marketSelected.scoring_rule_metadata}
        place="Feed"
      />
    )
  }
  const classList = classnames('view-feed', {
    'view-feed--authenticated': Boolean(currentUser),
    'view-feed--loading': constants.LOADING,
  })
  return (
    <div className={classList}>
      <ErrorHandler location={location}>
        <section className="view-feed__top">
          {isMobileSize && (
            <ErrorHandler location={location}>
              <CategoryNavigation
                title={i18n('markets.all_markets')}
                size="small"
                subcategories={categories}
              />
            </ErrorHandler>
          )}

          {!isMobileSize && (
            <div className="!px-4 md:!px-[30px] py-10">
              <HotTopic banners={banners} bannersLoading={bannersLoading} />
            </div>
          )}
        </section>
      </ErrorHandler>

      <ErrorHandler location={location} component="feed">
        {/*  look here for suspeese error */}
        <MarketListTemplate
          activeMarketType={activeMarketType}
          queryParams={queryParams}
          markets={feedIds.map(id => markets[id])}
          status={feedStatus}
          pagination={feedPagination}
          error={error}
          returnUrl={feedUrl()}
          onLoadMore={(activeMarketType, options) => {
            dispatch(fetchFeed(activeMarketType, { ...options, ...queryParams }))
          }}
          setOutcomeSelected={setOutcomeSelected}
          setMarketSelected={setMarketSelected}
          marketSelected={marketSelected}
          OutcomeSelected={outcomeSelected}
          page="feed"
        />
        <FAQs />

        {currentUser && currentUser.settings && !currentUser.settings.seen_onboarding_app && (
          <OnboardingTour isFromAllowedCountry={fromAllowedCountry} />
        )}
        {renderMakeBet(outcomeSelected)}
      </ErrorHandler>

      {renderHelmet()}
    </div>
  )
}

export default ViewFeed
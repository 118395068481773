import React from 'react'
import UserPicture from "profile/components/user-picture";

const SearchBoxUsersResult = ({ total, users = [], onViewAll, summary }) => {

  const renderUser = user => {
    if (user == null) {
      return null
    }
    return (
      <div key={user.username} className="flex flex-row gap-4 py-3 items-center cursor-pointer">
        <UserPicture user={user} width={48} height={48} enableNavigateToProfile={true}/> <h3 className="text-Neutral-6">{user.username}</h3>
      </div>
    )
  }
  const renderSummary = () => {
    const userResults = users.slice(0, 4).map(renderUser)

    if (!onViewAll) {
      console.warn('Summary and onViewAll props must be provided together')
    }

    return (
        <div>{userResults}</div>
    )
  }
  const renderComplete = () => {
    return users.map(renderUser)
  }

  if (total === 0) {
    return null
  }

  return (
    <div>
      {summary ? renderSummary() : renderComplete()}
    </div>
  )
}

export default SearchBoxUsersResult

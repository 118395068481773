import React, { lazy } from 'react'
import { howItWorksUrl } from 'landing/urls'
import { Navigate } from 'react-router-dom'
import { notFoundUrl, privacyPolicyUrl, termsUrl, contestRulesUrl, buyBitcoinInfoUrl } from 'urls'
import { locationErrorUrl } from 'urls'
import ViewFeed from 'views/feed'

const ViewFeedback = lazy(() => import('app/views/ViewFeedback'))
const ViewNotFound = lazy(() => import('app/components/ViewNotFound'))
const ViewPrivacyPolicy = lazy(() => import('app/components/ViewPrivacyPolicy'))
const ViewTerms = lazy(() => import('app/components/ViewTerms'))
const ViewContestRules = lazy(() => import('app/components/ViewContestRules'))
const ViewHowItWorks = lazy(() => import('landing/components/ViewHowItWorks'))
const ViewErrorLocationNotAllowed = lazy(() => import('views/error-location-not-allowed'))
const ViewHowToBuyBitcoin = lazy(() => import('views/how-to-buy-bitcoin'))

export default [
  { path: '/q/suggest', element: <Navigate to="/i/feedback/suggestion" replace /> },
  { path: ['/i/feedback', '/i/feedback/:type'], element: <ViewFeedback /> },
  { path: howItWorksUrl(), element: <ViewHowItWorks /> },
  { path: termsUrl(), element: <ViewTerms /> },
  { path: privacyPolicyUrl(), element: <ViewPrivacyPolicy /> },
  { path: contestRulesUrl(), element: <ViewContestRules /> },
  { path: notFoundUrl(), element: <ViewNotFound /> },
  { path: locationErrorUrl(), element: <ViewErrorLocationNotAllowed />, isModal: true },
  { path: buyBitcoinInfoUrl(), element: <ViewHowToBuyBitcoin />, isModal: true },
  { path: '/', element: <ViewFeed /> },
  { path: '/manifest', element: <ViewFeed /> },
];

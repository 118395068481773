import React from 'react';
import { Link } from 'react-router-dom';
import { RightArrowIcon } from 'common/components/icons';
import { categoryListUrl, keepQueryString } from 'urls';
import './CategoryResult.scss';
import getCatIcon from 'api/catergoryIcon';
import InfiniteGrid from "common/components/InfiniteGrid";

const CategoryResult = ({
                          categories,
                          total,
                          onViewAll,
                          isSummary,
                          onLoadMore = () => {},
}) => {
  const renderCategory = (options) => {

    const { index } = options
    const category = categories[index]

    if (category == null) {
      return null
    }

    const url = categoryListUrl(category);

    const displayName = () =>
      category?.tree?.map((subCategory) => [
        <Link
          to={keepQueryString(categoryListUrl(subCategory))}
          key={`category-${subCategory.id}`}
          className="category-list__item category-results__title"
        >
          {subCategory.title}
        </Link>,
        <span
          key={`category-${subCategory.id}-separator`}
          className="category-list__separator"
        >
          <RightArrowIcon width="5px" />
        </span>,
      ]);

    return (
      <Link key={category.id} className="category" to={url}>
        {category?.tree && (
          <div style={{ display: 'flex' }}>{getCatIcon(category.tree[0].slug)}</div>
        )}
        <div className="category__title">{displayName()}</div>
      </Link>
    );
  };

  const renderSummary = () => {
    const totalDisplay = Math.max(4, categories.length)

    if (!onViewAll) {
      console.warn('Summary and onViewAll props must be provided together');
    }

    return (
      <>
        <InfiniteGrid
          className="category-results"
          columnClassName="flex flex-col gap-6"
          totalRows={total}
          loadedRows={totalDisplay}
          onLoadMore={() => null}
          useMasonry={true}
          breakpoints={{ default: 1 }}
          onRowRender={renderCategory}
        />
      </>
    )
  };

  const renderComplete = () => {
    return (
      <InfiniteGrid
        className="category-results"
        columnClassName="flex flex-col gap-6"
        totalRows={total}
        loadedRows={categories.length === 0 ? 4 : categories.length}
        useMasonry={true}
        breakpoints={{ default: 1 }}
        onRowRender={renderCategory}
        onLoadMore={({ startIndex }) => {
          if (onLoadMore) {
            onLoadMore(startIndex)
          }
        }}
      />
    )
  }

  if (total === 0) {
    return null;
  }

  return (
      <div>
        {isSummary ? renderSummary() : renderComplete()}
      </div>
  );
};

export default CategoryResult;

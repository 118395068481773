import React from 'react'
import './CategoryMenu.scss'
import getCatIcon from 'api/catergoryIcon'
import {useSelector} from 'react-redux'
import {selectMainRootCategoryList} from 'store/categories'
import {categoryListUrl, keepQueryString} from 'urls'
import classNames from 'classnames'

const CategoryMenu = () => {
  const mainCategories = useSelector(selectMainRootCategoryList())
  const alphabetically = (a, b) => {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  }

  const renderCategoryEntry = category => {
    return (
      <li className="category_menu__category" key={category.id}>
        <a
          href={keepQueryString(categoryListUrl(category))}
          className="category_menu__category__maincategory">
          <div className="category_menu__category__maincategory">
            <i>{getCatIcon(category.slug, 'currentColor', '20px')}</i>
            <h2>{category.title}</h2>
          </div>
        </a>
        {category.children &&
          [...category.children].sort(alphabetically).map(child => (
            <a
              className="category_menu__category__subcategory"
              key={category.id + 'child' + child.id}
              href={keepQueryString(categoryListUrl({ ...child }))}>
              <div>{child.title}</div>
            </a>
          ))}
        <a
          className="category_menu__category__link"
          href={keepQueryString(categoryListUrl(category))}>
          View More
        </a>
      </li>
    )
  }

  return (
    <div className="category_menu">
      {
        <ul className={classNames('category_menu__list ')}>
          {[...mainCategories].sort(alphabetically).map(renderCategoryEntry)}
        </ul>
      }
    </div>
  )
}

export default CategoryMenu
